<!-- *************************** 找回密码 ****************************** -->
<template>
  <div class="register_container">
    <div class="container">
      <div class="outer">
        <div class="content">
          <h1 class="title">找回密码</h1>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
            <el-form-item prop="areaCode">
              <VueCountryIntl v-model="ruleForm.areaCode"></VueCountryIntl>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
              <el-input
                type="text"
                placeholder="请输入手机号"
                v-model="ruleForm.phone"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-row type="flex" justify="space-between">
              <el-col :span="14">
                <el-form-item label="验证码" prop="code">
                  <el-input
                    type="text"
                    placeholder="请输入验证码"
                    v-model="ruleForm.code"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="isShow" style="margin-top:40px">
                <el-button class="send_code" @click="sendCheckCode('ruleForm')">获取验证码</el-button>
              </el-col>
              <el-col :span="8" v-else style="margin-top:40px">
                <el-button class="send_code">({{seconds}})s</el-button>
              </el-col>
            </el-row>
            <el-form-item label="密码" prop="pass">
              <el-input
                type="password"
                placeholder="请输入密码"
                v-model="ruleForm.pass"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPass">
              <el-input
                type="password"
                placeholder="请输入密码"
                v-model="ruleForm.checkPass"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item class="submit">
              <el-button type="primary" @click="submitForm('ruleForm')">下一步</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { changePwdApi, getBackCodeApi } from "api/Login";
export default {
  data() {
    //校验手机
    var phone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        callback()
      }
    };
    //校验验证码
    var code = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
     //校验区号
    var areaCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择"));
      } else {
        callback();
      }
    };
    //校验密码
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/.test(value)) {
        callback("密码至少包含数字和英文,长度6-20");
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    //校验密码2
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        phone: "", //手机号
        code: "", //验证码
        pass: "",
        checkPass: "",
        areaCode: "61" //区号
      },
      rules: {
        phone: [{ validator: phone, trigger: "blur" }],
        code: [{ validator: code, trigger: "blur" }],
        areaCode: [{ validator: areaCode, trigger: "blur" }],
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }]
      },
      isShow: true,
      seconds: "",
      options: [
        {
          value: "+86",
          label: "中国 +86"
        },
        {
          value: "+83",
          label: "英国 +83"
        }
      ],
      timer: null
    };
  },
  methods: {
    //提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const { phone, code, areaCode, pass } = this.ruleForm;
          changePwdApi({
            phone: "+" + areaCode + " " + phone,
            code,
            password: pass
          }).then(res => {
            if (res.code == 0) {
              this.$router.push({ path: "/login" });
            }
          });
        } else {
          return false;
        }
      });
    },
    //发送验证码
    sendCheckCode(formName) {
      this.$refs[formName].validateField("phone", error => {
        if (!error) {
          const TIME_seconds = 15;
          if (!this.timer) {
            this.seconds = TIME_seconds;
            this.isShow = false;
            this.timer = setInterval(() => {
              if (this.seconds > 0) {
                this.seconds--;
              } else {
                this.isShow = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);

            const { phone, areaCode } = this.ruleForm;
            getBackCodeApi({
              phone: "+" + areaCode + " " + phone
            });
          }
        }
      });
    }
  },
};
</script>
<style scoped lang='scss'>
.register_container {
  background-size: cover;
  overflow: hidden;
  .outer {
    padding: 80px;
  }
  .content {
    color: #797979;
    width: 400px;
    background: #fff;
    border-radius: 5px;
    padding: 40px 20px;
    box-shadow: 0 0 10px #517cfc;
    margin: 0 auto;
    ::v-deep .vue-country-intl {
      .country-intl-label {
        width: auto !important;
        padding: 0 0 0 10px !important;
      }
      .country-intl-input-wrap {
        width: 100% !important;
        box-sizing: border-box !important;
      }
      .country-intl-input {
        width: 100% !important;
        box-sizing: border-box;
      }
      .vue-country-list {
        width: 100% !important;
        li {
          padding: 0 10px !important;
          box-sizing: border-box;
          text-align: left;
        }
      }
    }
    .title {
      padding-bottom: 35px;
      font-size: 30px;
      color: #517cfc;
    }
    .log {
      text-align: right;
      padding-bottom: 10px;
      a {
        color: #517cfc;
        text-decoration: underline;
      }
    }

    .el-input_inner {
      border: 1px solid #cdcdcd;
    }
    .el-button--primary {
      width: 100%;
      background-color: #517cfc;
      border-color: #517cfc;
    }
    .send_code {
      width: 118px;
      background: #517cfc;
      color: #fff;
    }
    .submit {
      margin-top: 15px;
      margin-bottom: 20px;
    }
  }
}
</style>